'use client';

import { FormEventHandler, useState } from 'react';
import { useRouter } from 'next/navigation';
import { z } from 'zod';
import { Alert, Box, TextField } from '@mui/material';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';
import { usePostForgotPasswordRequest } from '@/src/components/User/Settings/hooks/usePostForgotPasswordRequest';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
const schema = z.object({
  email: z.string({
    required_error: 'Email is required.'
  }).email('Email must be valid.')
});
export const ForgotPasswordForm = () => {
  const {
    hideDrawer
  } = useRightDrawerContext();
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const tenantAlias = useTenantAlias();
  const postForgotPasswordRequest = usePostForgotPasswordRequest({
    tenantAlias,
    onSuccess: () => {
      hideDrawer();
      router.replace('/forgotPasswordLinkSent');
    }
  });
  const handleSubmit: FormEventHandler = async event => {
    event.preventDefault();
    setErrorMessage('');
    const data = schema.safeParse({
      email
    });
    if (!data.success) {
      const formatted = data.error.errors.map(err => err.message).join(', ');
      setErrorMessage(formatted);
      return;
    }
    setIsSending(true);
    try {
      postForgotPasswordRequest.mutate({
        email: email
      });
    } catch (e) {
      if (isApiBusinessLogicError(e)) {
        setErrorMessage(e.message);
      }
    } finally {
      setIsSending(false);
    }
  };
  return <Box component="form" onSubmit={handleSubmit} data-sentry-element="Box" data-sentry-component="ForgotPasswordForm" data-sentry-source-file="ForgotPasswordForm.tsx">
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <Box sx={{
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: 2
    }} data-sentry-element="Box" data-sentry-source-file="ForgotPasswordForm.tsx">
        <TextField required fullWidth type="text" name="email" label="Email address" value={email} onChange={event => setEmail(event.target.value)} autoFocus={true} data-sentry-element="TextField" data-sentry-source-file="ForgotPasswordForm.tsx" />
        <LoadingButton type="submit" loading={isSending} size="large" fullWidth variant="contained" data-sentry-element="LoadingButton" data-sentry-source-file="ForgotPasswordForm.tsx">
          Continue
        </LoadingButton>
      </Box>
    </Box>;
};