import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { z } from 'zod';
import { recordEvent, setGA4CurrentUser } from '@qb/frontend/analytics/ga4';
import { disconnectSocket } from '@qb/httpRequest/SocketRequest';
import { SERVER_ERROR_MESSAGE } from '@qb/httpRequest/constants';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';
import {
  LoginPayload,
  UserInfo,
} from '@/shared/types/controllers/AuthControllerTypes';
import { useHasAuthenticatedLayoutCookie } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { setUser } from '@/src/store/reducers/main';

export const PASSWORD_MIN_LENGTH = 6;

const schema = z.object({
  email: z
    .string({
      required_error: 'Email is required.',
    })
    .trim()
    .email('Email must be valid.'),
  password: z
    .string({
      required_error: 'Password is required.',
    })
    .trim()
    .min(
      PASSWORD_MIN_LENGTH,
      `Password must be at least ${PASSWORD_MIN_LENGTH} characters.`,
    ),
});

export const useLoginRequest = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { hideDrawer } = useRightDrawerContext();
  const queryClient = useQueryClient();
  const tenantAlias = useTenantAlias();

  const {
    setHasAuthenticatedLayoutCookie,
    removeHasAuthenticatedLayoutCookie,
  } = useHasAuthenticatedLayoutCookie();

  const [errorMessage, setErrorMessage] = useState('');

  const submitLoginRequest = async (payload: LoginPayload) => {
    setErrorMessage('');

    const parsedPayload = schema.safeParse(payload);
    if (!parsedPayload.success) {
      const formatted = parsedPayload.error.errors
        .map((err) => err.message)
        .join(', ');
      setErrorMessage(formatted);
      return;
    }

    try {
      // TODO: rewrite using react-query
      const data = await spaQueries.auth
        .login(tenantAlias)
        .mutationFn(parsedPayload.data);

      if (data && typeof data.user === 'object') {
        const user = data.user as UserInfo;
        setHasAuthenticatedLayoutCookie();
        dispatch(setUser(user));
        disconnectSocket();

        queryClient.removeQueries();

        const redirectURL = getRedirectURL();
        if (redirectURL) {
          router.push(redirectURL);
        }
        hideDrawer();

        setGA4CurrentUser(user);
        recordEvent('login');
      } else {
        removeHasAuthenticatedLayoutCookie();
        toast.error(SERVER_ERROR_MESSAGE);
      }
    } catch (e) {
      if (isApiBusinessLogicError(e)) {
        setErrorMessage(e.message);
      }
    }
  };

  return { submitLoginRequest, errorMessage };
};

const getRedirectURL = () => {
  return '/';
};
