'use client';

import { Container, Paper } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { AuthFormLogo } from './AuthFormLogo';
type AuthPageContainerProps = React.PropsWithChildren<{
  outsideFormContent?: React.ReactNode;
}>;
export const AuthPageContainer = ({
  children,
  outsideFormContent
}: AuthPageContainerProps) => {
  const isMobile = useIsMobile();
  return <Container maxWidth={false} sx={{
    py: {
      xs: 2,
      sm: 4,
      md: 6
    }
  }} data-sentry-element="Container" data-sentry-component="AuthPageContainer" data-sentry-source-file="AuthPageContainer.tsx">
      <Paper sx={{
      border: 'none',
      width: '100%',
      maxWidth: 'sm',
      mx: 'auto',
      px: {
        xs: 2,
        sm: 3
      },
      py: {
        xs: 3,
        sm: 4
      },
      '& form': {
        mx: 'auto',
        width: '100%',
        maxWidth: 485,
        '.MuiFormLabel-asterisk, .MuiFormControlLabel-asterisk': {
          display: 'none'
        }
      }
    }} data-sentry-element="Paper" data-sentry-source-file="AuthPageContainer.tsx">
        {isMobile ? null : <AuthFormLogo />}
        {children}
      </Paper>
      {outsideFormContent}
    </Container>;
};