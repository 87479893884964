'use client';

import { useSearchParams } from 'next/navigation';
import { AuthDrawerContainer } from '../components/AuthDrawerContainer';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { AuthPageContainer } from '../components/AuthPageContainer';
import { LoginForm } from './LoginForm';
export type LoginViewHeaderType = 'initialEmail' | undefined;
type LoginViewProps = {
  viewType: 'drawer' | 'page';
  headerTextsType?: LoginViewHeaderType;
  initialEmail?: string;
};
export const LoginView = ({
  viewType,
  headerTextsType,
  initialEmail
}: LoginViewProps) => {
  const searchParams = useSearchParams();
  const finalHeaderTextType = (() => {
    if (searchParams.has('headerTextsType')) {
      return (searchParams.get('headerTextsType') as LoginViewHeaderType);
    }
    if (headerTextsType) {
      return headerTextsType;
    }
    return undefined;
  })();
  const finalInitialEmail = (() => {
    if (searchParams.has('initialEmail')) {
      return searchParams.get('initialEmail');
    }
    if (initialEmail) {
      return initialEmail;
    }
    return undefined;
  })();
  const {
    title,
    subtitle
  } = getTexts(finalHeaderTextType);
  if (viewType === 'drawer') {
    return <AuthDrawerContainer>
        <AuthFormHeader title={title} subtitle={subtitle} />
        <LoginForm initialEmail={finalInitialEmail} headerTextsType={finalHeaderTextType} />
      </AuthDrawerContainer>;
  }
  return <AuthPageContainer data-sentry-element="AuthPageContainer" data-sentry-component="LoginView" data-sentry-source-file="LoginView.tsx">
      <AuthFormHeader title={title} subtitle={subtitle} data-sentry-element="AuthFormHeader" data-sentry-source-file="LoginView.tsx" />
      <LoginForm shouldRedirect initialEmail={finalInitialEmail} headerTextsType={finalHeaderTextType} data-sentry-element="LoginForm" data-sentry-source-file="LoginView.tsx" />
    </AuthPageContainer>;
};
const getTexts = (textsType: LoginViewProps['headerTextsType']) => {
  switch (textsType) {
    case 'initialEmail':
      return {
        title: 'Welcome back',
        subtitle: `It's good to see you again. Looks like you already have an account. Please log in to access your account.`
      };
    default:
      return {
        title: 'Welcome to Sales Pilot Agent Portal',
        subtitle: 'Login using your provided agent email'
      };
  }
};