'use client';

import { PropsWithChildren, useCallback, MouseEventHandler, forwardRef, Ref } from 'react';
import Link, { LinkProps } from 'next/link';
type Props = PropsWithChildren<LinkProps & {
  shouldRedirect: boolean;
}>;

/**
 * There are many cases in our app where we need to change the link/button behaviour based on other conditions.
 * For example: in auth pages, we either redirect if inside dedicated page or show a drawer if inside a drawer.
 * Or in the navbar: we either redirect on mobile or show drawer on desktop.
 */
export const LinkOnClickOrRedirect = forwardRef(function LinkOnClickOrRedirectInternal({
  href,
  onClick,
  children,
  shouldRedirect = false,
  ...props
}: Props, ref: Ref<HTMLAnchorElement> | undefined) {
  const onClickHandler = useCallback<MouseEventHandler<HTMLAnchorElement>>(event => {
    if (!shouldRedirect) {
      event.preventDefault();
      if (onClick) {
        onClick(event);
      }
    }
  }, [onClick, shouldRedirect]);
  return <Link ref={ref} href={href} onClick={onClickHandler} {...props}>
        {children}
      </Link>;
});