import { Container } from '@mui/material';
import { AuthFormLogo } from './AuthFormLogo';
export const AuthDrawerContainer = ({
  children
}: React.PropsWithChildren) => <Container maxWidth="sm" sx={{
  '& form': {
    mx: 'auto',
    width: '100%',
    maxWidth: 485,
    '.MuiFormLabel-asterisk, .MuiFormControlLabel-asterisk': {
      display: 'none'
    }
  }
}} data-sentry-element="Container" data-sentry-component="AuthDrawerContainer" data-sentry-source-file="AuthDrawerContainer.tsx">
    <AuthFormLogo data-sentry-element="AuthFormLogo" data-sentry-source-file="AuthDrawerContainer.tsx" />
    {children}
  </Container>;