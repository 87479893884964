import { AuthDrawerContainer } from '../components/AuthDrawerContainer';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { AuthPageContainer } from '../components/AuthPageContainer';
import { ForgotPasswordForm } from './ForgotPasswordForm';
const TITLE = 'Reset Password';
const SUBTITLE = `Enter the email address associated with your account, and we'll email you a link to reset your password.`;
type ForgotPasswordViewProps = {
  viewType: 'drawer' | 'page';
};
export const ForgotPasswordView = ({
  viewType
}: ForgotPasswordViewProps) => {
  if (viewType === 'drawer') {
    return <AuthDrawerContainer>
        <AuthFormHeader title={TITLE} subtitle={SUBTITLE} />
        <ForgotPasswordForm />
      </AuthDrawerContainer>;
  }
  return <AuthPageContainer data-sentry-element="AuthPageContainer" data-sentry-component="ForgotPasswordView" data-sentry-source-file="ForgotPasswordView.tsx">
      <AuthFormHeader title={TITLE} subtitle={SUBTITLE} data-sentry-element="AuthFormHeader" data-sentry-source-file="ForgotPasswordView.tsx" />
      <ForgotPasswordForm data-sentry-element="ForgotPasswordForm" data-sentry-source-file="ForgotPasswordView.tsx" />
    </AuthPageContainer>;
};